




import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import ScanDisabledProviders from '@/modules/common/modules/rates/constants/scan-disabled-providers.enum';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import RoomsTypeManagerService, { RoomsTypeManagerServiceS } from '../rooms-type-manager.service';

@Component({
    components: { CustomSelect },
})
export default class RoomsProviderSelect extends Vue {
    @Inject(RoomsTypeManagerServiceS) private hotelsRoomTypeService!: RoomsTypeManagerService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(ProvidersServiceS) private providersService!: ProvidersService;

    get provider() {
        return this.hotelsRoomTypeService.source;
    }

    set provider(value) {
        this.hotelsRoomTypeService.saveSource(value);
    }

    getProviderLabel(providerName: string) {
        const { data } = this.providersService;

        if (!data) {
            return providerName;
        }

        const provider = data.find(currentProvider => currentProvider.name === providerName);

        if (!provider) {
            return '';
        }

        return provider.label || provider.name || '';
    }

    get providerItems(): Item[] {
        if (!this.hotelsRoomTypeService.providers) {
            return [];
        }

        return this.hotelsRoomTypeService.providers.map((provider: string, i) => ({
            value: provider,
            name: this.getProviderLabel(provider),
        })).filter(val => val.value !== ScanDisabledProviders.ALL);
    }
}
