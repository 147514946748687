



































































import RoomTypeEditDropdown from '@/modules/rooms-type-manager/components/room-types-dot-menu.vue';
import { Vue, Component } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import { Inject } from 'inversify-props';
import RoomsTypeManagerService, { RoomsTypeManagerServiceS } from '@/modules/rooms-type-manager/rooms-type-manager.service';
import RoomTypesService, { RoomTypesServiceS } from '@/modules/room-types/room-types.service';
import RoomsProviderSelect from '@/modules/rooms-type-manager/components/rooms-provider-select.vue';
import HotelDraggable from '@/modules/rooms-type-manager/components/hotel-draggable.vue';
import RoomTypeModel from '@/modules/room-types/models/room-type.model';
import AddRoomPopup from '@/modules/room-types/components/add-room.popup.vue';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import CustomLoader from '@/modules/common/components/ui-kit/custom-loader.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';

@Component({
    components: {
        AddRoomPopup,
        HotelDraggable,
        RoomsProviderSelect,
        draggable,
        ModalWrapper,
        LoaderWrapper,
        RoomTypeEditDropdown,
        CustomLoader,
    },
})
export default class RoomsTypeMappingPopup extends Vue {
    @Inject(RoomsTypeManagerServiceS) protected hotelsRoomTypeService!: RoomsTypeManagerService;
    @Inject(RoomTypesServiceS) protected roomTypesService!: RoomTypesService;
    @Inject(UserServiceS) private userService!: UserService;

    get localChanges(): boolean {
        return Boolean(this.hotelsRoomTypeService.storeState.localHotelsRoomType);
    }

    get hotels(): { name: string, id: number }[] | null {
        const { hotels } = this.hotelsRoomTypeService;
        if (!hotels) {
            return null;
        }
        return hotels.sort(a => (a.id === +this.$route.params.hotelId
            ? -1 : 1));
    }

    get roomTypes(): RoomTypeModel[] | null {
        return this.roomTypesService.realRooms;
    }

    get isLoading() {
        return this.hotelsRoomTypeService.storeState.loading.isLoading();
    }

    get isUserDemo() {
        return this.userService.isDemo;
    }

    toggleRoomTypeModal() {
        return this.$router.push({
            name: `${this.$route.name!}.new-room-type`,
        });
    }

    async handleUpdate() {
        await this.hotelsRoomTypeService.save();
        this.closePopup();
    }

    closePopup() {
        const routeIndex = this.$route.path.indexOf('/rooms-mapping');
        this.$router.push(this.$route.fullPath.slice(0, routeIndex));
    }

    confirm() {
        if (this.localChanges) {
            this.$router.push({
                name: `${this.$route.name!}.confirm-close`,
            });
        } else {
            this.closePopup();
        }
    }
}
