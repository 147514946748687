










import RoomsTypeManagerService, { RoomsTypeManagerServiceS } from '@/modules/rooms-type-manager/rooms-type-manager.service';
import RoomTypesService, { RoomTypesServiceS } from '@/modules/room-types/room-types.service';
import { Inject } from 'inversify-props';
import { Component, Vue, Prop } from 'vue-property-decorator';
// @ts-ignore
import CustomDropdown, { IDropdownItem } from '@/modules/common/components/ui-kit/custom-dropdown.vue';

@Component({
    components: {
        CustomDropdown,
    },
})
export default class RoomTypeEditDropdown extends Vue {
    @Inject(RoomTypesServiceS) private roomTypesService!: RoomTypesService;
    @Inject(RoomsTypeManagerServiceS) private hotelsRoomTypeService!: RoomsTypeManagerService;

    @Prop({ required: true })
    private roomTypeId!: number;

    get items(): IDropdownItem[] {
        return [
            {
                link: `edit-room-type/${this.roomTypeId}`,
                text: 'Rename',
            },
            {
                link: `delete-room-type/${this.roomTypeId}`,
                text: 'Delete',
                disable: this.isStandardLevel || this.hotelsRoomTypeService.isMappedRomType(this.roomTypeId),
            },
        ];
    }

    get isStandardLevel() {
        const roomName = this.roomTypesService.roomName(this.roomTypeId);
        if (!roomName) {
            return false;
        }
        return /standard level/i.test(roomName);
    }
}
