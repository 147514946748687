











































import { Dropdown, DropdownItem, DropdownMenu } from 'element-ui';
import {
    Vue, Component, Prop, InjectReactive,
} from 'vue-property-decorator';
import UserService, { UserServiceS } from '@/modules/user/user.service';

import RoomTypeModel from '@/modules/room-types/models/room-type.model';
import { Inject } from 'inversify-props';
import RoomsTypeManagerService, { RoomsTypeManagerServiceS } from '../rooms-type-manager.service';

export interface Room {
    name: string,
    price: number
}

@Component({
    components: {
        'el-dropdown': Dropdown,
        'el-dropdown-menu': DropdownMenu,
        'el-dropdown-item': DropdownItem,
    },
})
export default class HotelRoom extends Vue {
    @Inject(RoomsTypeManagerServiceS)
    private roomTypeManagerService!: RoomsTypeManagerService;

    @Inject(UserServiceS)
    private userService!: UserService;

    @Prop({
        required: false,
        type: Object,
    })
    private hotelRoom!: Room;

    @Prop({
        required: true,
        type: Number,
    })
    private hotelId!: number;

    @Prop({
        required: true,
        type: Array,
    })
    public roomTypes!: RoomTypeModel[];

    @InjectReactive('isGlobalLoading')
    private isGlobalLoading!: boolean;

    isUpdating = false;

    get ignoreList() {
        return this.roomTypeManagerService
            .storeState.ignoredRoomNames[this.hotelId] || [];
    }

    get isIgnoringAllowed() {
        return this.userService.isAdmin
            && this.hotelId === +this.$route.params.hotelId;
    }

    get isDisabled() {
        if (!this.isIgnoringAllowed) return false;

        return this.isUpdating
            || this.roomTypeManagerService.isIgnoreListUpdating
            || this.isGlobalLoading;
    }

    get isIgnored() {
        if (!this.isIgnoringAllowed) return false;

        return this.ignoreList.includes(this.hotelRoom.name);
    }

    handleRoomSelection(roomType: RoomTypeModel) {
        this.$emit('changeRoom', { name: this.hotelRoom.name, newRoomType: roomType.id });
    }

    async toggleIgnore() {
        try {
            this.isUpdating = true;
            await this.roomTypeManagerService
                .toggleIgnoreRoom(this.hotelId, this.hotelRoom.name);
        } finally {
            this.isUpdating = false;
        }
    }
}
