































import {
    Component, Prop, Vue, ProvideReactive,
} from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import RoomTypesService, { RoomTypesServiceS } from '@/modules/room-types/room-types.service';
import RoomTypeModel from '@/modules/room-types/models/room-type.model';
import draggable, { MoveEvent, EndEvent } from 'vuedraggable';
import HotelRoom, { Room } from '@/modules/rooms-type-manager/components/hotel-room.vue';
import RoomsTypeManagerService, { RoomsTypeManagerServiceS } from '../rooms-type-manager.service';

@Component({
    components: {
        draggable,
        HotelRoom,
    },
})
export default class HotelDraggable extends Vue {
    @Inject(RoomsTypeManagerServiceS)
    protected hotelsRoomTypeService!: RoomsTypeManagerService;

    @Inject(RoomTypesServiceS)
    private roomTypesService!: RoomTypesService;

    @Prop({ required: true })
    private hotelId!: number;

    @ProvideReactive('ignoreList')
    private ignoreList: string[] = [];

    @ProvideReactive('isGlobalLoading')
    public isLoading = false;

    get isMainHotel() {
        return this.hotelId === +this.$route.params.hotelId;
    }

    get roomTypes(): RoomTypeModel[] | null {
        return this.roomTypesService.realRooms;
    }

    getRooms(roomTypeId: number) {
        const list: Room[] = [];
        const roomsByHotel = this.hotelsRoomTypeService.roomsByHotel(this.hotelId);

        if (!roomsByHotel) {
            return list;
        }

        Object.keys(roomsByHotel).forEach(key => {
            if (roomsByHotel[key] === roomTypeId) {
                list.push({
                    name: key,
                    price: 190,
                });
            }
        });

        return list;
    }

    beforeMount() {
        this.loadIgnoreList();
    }

    async loadIgnoreList() {
        if (!this.isMainHotel) return;

        this.isLoading = true;

        try {
            this.ignoreList = await this.hotelsRoomTypeService
                .getIgnoredRoomList(this.hotelId);
        } finally {
            this.isLoading = false;
        }
    }

    isDraggable(event: MoveEvent<Room>) {
        return event.from !== event.to;
    }

    handleMove(event: EndEvent) {
        const toRoomTypeId = Number(event.to.id);
        this.hotelsRoomTypeService.addLocalChanges(this.hotelId, event.item.id, toRoomTypeId);
    }

    changeRoom(event: { name: string, newRoomType: number }) {
        this.hotelsRoomTypeService.addLocalChanges(this.hotelId, event.name, event.newRoomType);
    }
}
