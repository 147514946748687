




import { Vue, Component } from 'vue-property-decorator';
import RoomsTypeMappingPopup from '@/modules/rooms-type-manager/components/rooms-type-mapping.popup.vue';

@Component({
    components: {
        RoomsTypeMapping: RoomsTypeMappingPopup,
    },
})
export default class RoomMappingModalPage extends Vue {}

